import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Uizard"
    img_name="uizard_logo.png"
    link="https://uizard.io/"
    description="Uizard is the best screen design tool for beginners and UX designers. It’s ideal for quick and easy creation of app wireframes and website mockups."
    twitterHandleTool="@uizardIO"
  />
)

export default Tool;


