import * as React from "react"
import Styled from 'styled-components/macro';

import Meta from './meta'
import Layout from "./layout"
import Content from './content'
import Container from './container'
import Social from './social'

const Screen = Styled.div`
    margin: 120px 0 0 0;
    background-color: ${(props) => props.theme.colors.secondary};
    min-height: 33rem;
`;

const NavButtonLink = Styled.a`
    cursor: pointer;
`;

const NavButtonLinkTool = Styled.a`
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
`;

const ButtonCard = Styled.div`
    display: block;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.regular};
    background-color: ${(props) => props.theme.colors.black};
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.secondary};
    padding: 2rem 3rem;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    width: 350px;
    margin: 0 2rem 2rem 0;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.black};
    }

    @media (max-width: 900px) {
        width: 100%;
        margin: 0 0 2rem 0;
    }
`;

const CTA = Styled(ButtonCard)`
    width: auto;
    padding: 1rem 3rem;
    margin: 0 3rem;
`;

const Label = Styled.h6`
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0;
`;

const LabelCTA = Styled(Label)`
    font-size: 1.6rem;
    margin: 0;
`;

const Title = Styled.h5`
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 600;
    color: inherit;
    letter-spacing: -0.3px;
    margin: 10rem 0 6rem 0;
`;

const Name = Styled.h6`
    font-size: 3rem;
    font-weight: 600;
    margin: 0;

    @media (max-width: 900px) {
        font-size: 2rem;
    }
`;

const Description = Styled.p`
    font-size: 3rem;
    margin: 6rem 0;
`;

const Logo = Styled.img`
  display: block;
  height: 8rem;
  margin-right: 3rem;

  @media (max-width: 900px) {
    height: 5rem;
  }
`;

const Row = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max: 100%;
  margin: 0 auto;
`;

const Column = Styled.div`
`;


export default ({
    name, img_name, link, description, twitterHandleTool
  }) => (
    <Layout green>
        <Meta
            title="The Best Design Software"
            description="Which design tool is the best for your needs?"
            url="/app"
        />
        <Container green>
            <Content>
                <Screen>
                    <Title>You need to use:</Title>
                    
                    <Row>
                        <Logo src={"/tools/"+img_name} alt={"img logo for software "+name} />
                        
                        <Column>
                            <Name>{name}</Name>
                            <NavButtonLinkTool href={link+"?ref=bestdesignsoftware.io"} target="_blank">
                                {link}
                            </NavButtonLinkTool>
                        </Column>

                        <NavButtonLink href={link+"?ref=bestdesignsoftware.io"} target="_blank">
                            <CTA>
                                <LabelCTA>Get it</LabelCTA>
                            </CTA>
                        </NavButtonLink>
                    </Row>
                    
                    <Description>{description}</Description>
                </Screen>
            </Content>
            <Social twitterHandleTool={twitterHandleTool}/>
        </Container>
    </Layout>
  )
  