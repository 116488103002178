import * as React from 'react';
import Styled from 'styled-components/macro';

const ExtendedButton = Styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: ${(props) => props.theme.fonts.regular};
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5rem;
  height: 5rem;
  background-color: ${(props) => props.theme.colors.black};
  border: 1px solid transparent;
  color: ${(props) => (props.green ? props.theme.colors.secondary : props.theme.colors.primary)};
  padding: 0 6rem;
  cursor: pointer;
  text-align: center;
  outline: none;
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => (props.green ? props.theme.colors.primary : props.theme.colors.secondary)};
    color: ${(props) => props.theme.colors.black};
  }
`;

const Button = ({children, green}) => (
    <ExtendedButton type="button" green={green}>
        {children}
    </ExtendedButton>
);

export default Button;