import * as React from 'react';
import Styled from 'styled-components/macro';

import Content from './content'
import Container from './container'
import Button from './button'

const ContainerExtended = Styled(Container)`
    margin-bottom: 120px;
`;

const Row = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max: 100%;
  margin: 0 auto;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const NavButtonLink = Styled.a`
    cursor: pointer;
    margin-right: 3rem;

    @media (max-width: 900px) {
        margin: 0 0 2rem 0;
    }
`;

const Label = Styled.span`
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
`;

const Icon = Styled.svg`
    margin-right: 1rem;
    stroke: ${(props) => props.theme.colors.black};
`;

const SocialButton = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.regular};
    border: 2px solid ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.black};
    width: auto;
    padding: 1rem 3rem 1rem 2rem;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.black};
        border: 2px solid transparent;

        svg {
            stroke: ${(props) => props.theme.colors.black};
            fill: ${(props) => props.theme.colors.black};
        }
    }

    @media (max-width: 900px) {
        width: 100%;
    }
`;

const TwitterBtn = Styled(SocialButton)`
`;

const FacebookBtn = Styled(SocialButton)`
`;


export default ({
    twitterHandleTool
  }) => (
    <ContainerExtended green>
        <Content>
            <Row>
                <NavButtonLink href="/app">
                    <Button green={true}>
                        Try again
                    </Button>
                </NavButtonLink>
                    
                <NavButtonLink href={"https://twitter.com/intent/tweet?text=What's%20the%20best%20design%20tool%20for%20you?%20For%20me,%20it's%20"+twitterHandleTool+"&url=http://bestdesignsoftware.io/app/&hashtags=100DaysOfCode,design,designTool,designSoftware"} target="_blank">
                    <TwitterBtn>
                        <Icon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path data-v-05cacbfc="" stroke="none" fill="none"></path><path data-v-05cacbfc="" d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"></path></Icon>
                        <Label>Tweet me</Label>
                    </TwitterBtn>
                </NavButtonLink>

                <NavButtonLink href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fbestdesignsoftware.io%2Fapp%2F" target="_blank">
                    <FacebookBtn>
                        <Icon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path data-v-05cacbfc="" stroke="none" fill="none"></path><path data-v-05cacbfc="" d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path></Icon>
                        <Label>Share me</Label>
                    </FacebookBtn>
                </NavButtonLink>
            </Row>
        </Content>
    </ContainerExtended>
 )